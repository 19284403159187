@import '../theme/variables';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
}
.fadeOut {
  animation: 0.2s fadeOut forwards;
}
